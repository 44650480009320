<template>
  <v-menu
    :value="useModel && value"
    offset="4"
    @update:model-value="(e) => useModel && $emit('update:modelValue', e)"
  >
    <template #activator="{ isActive, props }">
      <slot
        v-if="visibleActivator"
        v-bind="{ isActive, props }"
      />
    </template>
    <v-list
      rounded
      border="0"
      elevation="10"
      density="compact"
      class="pa-2"
    >
      <v-list-item
        v-for="(item, key) in items"
        :key="key"
        rounded
        class="pa-2"
        @click="emit('select', item)"
      >
        <template #prepend>
          <v-icon
            v-if="item.icon"
            :icon="item.icon"
            size="16"
            class="text-beton64"
          />
        </template>

        <v-list-item-title
          class="text-h4 text-beton64"
          v-text="item.label"
        />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
defineOptions({
  name: 'XDropDown',
});

type TItem = { label: string; icon?: string; value?: string; callback?: () => {} };

const {
  useModel = false,
  items = [],
  visibleActivator = true,
} = defineProps<{
  items: Array<TItem>;
  useModel?: boolean;
  visibleActivator?: boolean;
}>();

const emit = defineEmits<{
  (_eventName: 'select', _item: TItem): void;
  (_eventName: 'update:modelValue', _e: Event): void;
}>();

const value = defineModel<boolean>({ default: false });
</script>

<style lang="scss" scoped></style>
